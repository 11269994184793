.auto_content[data-v-571d84b2] {
  margin: 0 0 5px;
}
.content_select[data-v-571d84b2] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.content_select .left_select[data-v-571d84b2] {
  display: flex;
  align-items: center;
}
.item_icon[data-v-571d84b2] {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  color: #333;
}
.item_icon i[data-v-571d84b2] {
  font-size: 13px;
  margin-right: 2px;
}
.select_width[data-v-571d84b2] {
  width: 140px !important;
}
.flex_between > div[data-v-571d84b2] {
  width: 80%;
}